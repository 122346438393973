/*
 * This file should include the imports to each indvidual js file you create plus some extras like jquery
 * and the base foundation bits.
 */
import "what-input";

// If you want all the foundation JS, uncomment the line below
// require('foundation-sites');

// If you want to pick and choose which modules to include, comment out the above and uncomment the line below
import "./lib/foundation-explicit-pieces";

import "./lib/wp-foundation";

import "./swiper";

// Place Extra JS here
const textToTruncate = document.querySelectorAll(".truncated-text");
const textToTruncate2 = document.querySelectorAll(".truncated-text-longer");

const toggleTruncate = (item) => {
  const ps = item.querySelectorAll("p");
  const ul = item.querySelectorAll("ul");

  ps.forEach((p, index) => {
    p.classList.toggle("hide");
  });
  ul.forEach((p, index) => {
    p.classList.toggle("hide");
  });
};
const toggleTruncate2 = (item) => {
  const ps = item.querySelectorAll("p");
  const ul = item.querySelectorAll("ul");
  const btn = item.querySelectorAll(".button-wrapper");

  ps.forEach((p, index) => {
    p.classList.toggle("hide");
  });
  ul.forEach((p, index) => {
    p.classList.toggle("hide");
  });
  btn.forEach((p, index) => {
    p.classList.toggle("hide");
  });
};

const createSnippit = (item) => {
  const snippitEl = document.createElement("p");
  snippitEl.classList.add("snippit", "hide");
  const snippit =
    item.querySelector("p").innerHTML.split(" ").splice(0, 20).join(" ") +
    "...";
  /* 	const snippit =
		item.querySelector("p").innerHTML.split(".").splice(0, 2).join(". ") +
		"..."; */
  snippitEl.innerHTML = snippit;
  item.prepend(snippitEl);
};
const createSnippit2 = (item) => {
  const snippitEl = document.createElement("p");
  snippitEl.classList.add("snippit", "hide");
  const snippit = item.querySelector("p").innerHTML + "...";
  /* 	const snippit =
		item.querySelector("p").innerHTML.split(".").splice(0, 2).join(". ") +
		"..."; */
  snippitEl.innerHTML = snippit;
  item.prepend(snippitEl);
};

textToTruncate.forEach((item) => {
  const readMore = document.createElement("button");
  readMore.innerHTML = "Read More";
  readMore.classList = "rm-btn";
  item.after(readMore);

  createSnippit(item);

  toggleTruncate(item);

  readMore.addEventListener("click", () => {
    if (item.classList.contains("open")) {
      readMore.innerHTML = "Read More";
    } else {
      readMore.innerHTML = "Read Less";
    }
    item.classList.toggle("open");
    toggleTruncate(item);
  });
});
textToTruncate2.forEach((item) => {
  const readMore = document.createElement("button");
  readMore.innerHTML = "Read More";
  readMore.classList = "rm-btn rm-btn--alt";
  item.after(readMore);

  createSnippit2(item);

  toggleTruncate2(item);

  readMore.addEventListener("click", () => {
    if (item.classList.contains("open")) {
      readMore.innerHTML = "Read More";
    } else {
      readMore.innerHTML = "Read Less";
    }
    item.classList.toggle("open");
    toggleTruncate2(item);
  });
});
