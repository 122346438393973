import Swiper, { Navigation, Autoplay, Pagination } from "swiper";

const testimonialSwiper = new Swiper(".testimonial-swiper", {
	direction: "horizontal",
	loop: true,
	autoHeight: true,
	spaceBetween: 80,
	slidesPerView: 1,
	pagination: {
		el: ".swiper-pagination",
		clickable: true,
	},
	autoplay: {
		delay: 5000,
	},
	navigation: {
		nextEl: ".swiper-button-next",
		prevEl: ".swiper-button-prev",
	},
	modules: [Autoplay, Navigation, Pagination],
});
